<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{$t('Order Information')}}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >{{$t('Check Order Information')}}</span
        >
      </div>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Heading-->
        <div class="row">
          <label class="col-xl-3"></label>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Client')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="order.client.name"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Zone')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="zoneName"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('status')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="order.status"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Customer')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="order.customer.name"
              disabled
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Driver')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="dname"
              
              disabled
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Driver Phone')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="dphone"
              
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Type')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="dtype"
              
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Notes')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="dnotes"
              
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Date')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="ddate"
              
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Pickedupat')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="order.pickedup_at"
              
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('DeliveredAt')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="order.delivered_at"
              
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Is Address Confirmed')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="confirmed"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Fees')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="order.fees"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Amount')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="order.amount"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Created By</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="order.created_by"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Fees Type</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="order.fees_type"
              disabled
            />
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  name: "OrderInformation",
  
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({ clients: "clients/table" }),
    ...mapGetters({ zone: "zones/table" }),
  },
  beforeMount(){
    if(this.order.driver == null){
      this.dname = '-'
      this.dphone ='-'
    }else{
      this.dname = this.order.driver.full_name
      this.dphone = this.order.driver.phone
      
    }

    if(this.order.notes == null && this.order.delivery_date ==null && this.order.vehicle_type ==null){
      this.dnotes ='-' 
      this.ddate = '-'
      this.dtype = '-'
    }else{
      this.dnotes = this.order.notes 
      this.ddate = this.order.delivery_date
      if(this.order.vehicle_type ==0){
        this.dtype = 'Car'
      }else{
        this.dtype = 'Scooter'
      }
      if(this.order.notes === null){
        this.dnotes = '-'
      }
      
    }

  },
  async mounted() {
    
    await this.$store
      .dispatch("clients/getClientZone", this.order.client)
      .then((res) => {
        
        var x = res.filter((b) => b.id == this.order.zone_id);
        this.zoneName = x[0].name_ar;
      });

    this.fees = this.order.fees + " JOD";
    this.amount = this.order.amount + " JOD";

    
    if(this.order.isAddressConfirmed == '1' || this.order.isAddressConfirmed == 1){
      this.confirmed = 'Confirmed'
    }
    else{
      this.confirmed = ' Not Confirmed'
    }
  },
  data() {
    return {
      clientname: "",
      zoneName: "",
      fees: "",
      amount: "",
      confirmed:'',
      dname:'',
      dphone:'',
      dnotes:'',
      ddate:'',
      dtype:''
    };
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // set spinner to submit button
        const submitButton = this.$refs["kt_save_changes"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send update request
          this.$store.dispatch("drivers/update", this.driver);

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );

          Swal.fire({
            title: "",
            text: "Driver has been update successfully!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });
        }, 2000);

        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
    },
    cancel() {},
  },
};
</script>


<style scoped>
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}
</style>