<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{$t('Trip Map')}}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >{{$t('Tracking The Order')}}</span
        >
      </div>
      <div class="card-toolbar">
        
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Heading-->
        <div class="row">
          <label class="col-xl-3"></label>
          
        </div>
       <div class="col-xl-15">
                          <template>
                            <GmapMap
                              style="width: 100%; height: 30vh"
                              :center="{
                                lat: currentLocation.latitude,
                                lng: currentLocation.longitude,
                              }"
                              :zoom="17"
                            >
                              <GmapMarker
                                label="★"
                                :position="{
                                  lat: this.currentLocation.latitude,
                                  lng: this.currentLocation.longitude,
                                }"
                              />
                            </GmapMap>
                          </template>
                        </div>

        



      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";


export default {
  name: "TripMap",

  props: {
    order: {
        type: Object,
        required: true,
    },
  },
  data() {
    return {
        currentLocation: { latitude: 0, longitude: 0 },
      
    };
  },
  methods: {
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch('drivers/update', this.driver);

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );

        Swal.fire({
          title: "",
          text: "Driver has been update successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        });
      }, 2000);


        this.submitStatus = 'PENDING'
        setTimeout(() => {
          this.submitStatus = 'OK'
        }, 500)}



    },
    cancel() {

    },
  },
};
</script>


<style scoped>
.form-group--error{
    color: rgba(255, 0, 0, 0.8);
    
  }
  .form-input--error{
    border: rgba(255, 0, 0, 0.5) 1px solid;
  }
  .form-input--error:focus{
    border: rgba(255, 0, 0, 0.5) 1px solid;
  }
  
  .form-select--error{
    border: rgba(255, 0, 0, 0.5) 1px solid;
    border-radius: 5px;
  }
</style>